import { FC } from "react";
import { Link } from "react-router-dom";
import { useLayout } from "../../core";
import { AsideMenu } from "./AsideMenu";
import { APP_NAME } from "../../../../app/config";

export default function AsideDefault() {
  const { classes } = useLayout();
  return (
    <div className="aside border-end border-gray-300" style={{ backgroundColor: "#FAFAFA" }}>
      <div className="flex-column-auto h-55px d-flex align-items-center justify-content-center border-bottom border-gray-300">
        <Link to="/dashboard">
          <span className="text-gray-700 fw-bolder text-uppercase ls-1 fs-7">{APP_NAME}</span>
        </Link>
      </div>
      <div className="flex-column-fluid">
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>
      <div className="aside-footer"></div>
    </div>
  );
}
