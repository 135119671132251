import { useContext } from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import { AppLayoutContext } from "../context/LayoutContext";
import { AppButton } from "./Form";

const _getModeIcon = (mode) => {
  switch (mode) {
    case "danger":
      return "fa fa-exclamation-triangle text-danger";
    case "warning":
      return "fa fa-exclamation-circle text-warning";
    case "success":
      return "fa fa-check-circle text-primary";
    default:
      return "fa fa-info-circle text-gray-700";
  }
};

export default function AppDialog() {
  const intl = useIntl();
  const { dialog } = useContext(AppLayoutContext);
  const { enabled, mode, title, body, confirmButtonTitle, cancelButtonTitle, onConfirm, close } = dialog;
  return (
    <Modal show={enabled} centered onHide={close} animation={false} contentClassName="rounded-0">
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="d-flex align-items-center text-gray-700">
            <span className={_getModeIcon(mode)} />
            <span className="ms-3">{intl.formatMessage({ id: title ?? "-" })}</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <div className="d-flex align-items-center">
          <AppButton className="btn btn-light me-3" title={cancelButtonTitle ?? "BTN.CANCEL"} onClick={close} />
          <AppButton
            className="btn btn-primary"
            title={confirmButtonTitle ?? "BTN.CONFIRM"}
            onClick={() => {
              close();
              onConfirm();
            }}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
}
