import { useContext, useEffect } from "react";
import io from "socket.io-client";
import { SOCKET_API_URL } from "../app/config";
import { AuthContext } from "../app/context/AuthContext";
import EventService from "../app/services/event.service";
const audio = new Audio("/media/mp3/beep.mp3");

const _EVENTCODES = ["NEW.MESSAGE", "LEAD.UPDATED"];

const beep = () => {
  audio
    .play()
    .then(() => {})
    .catch((error) => {
      return;
    });
};

export default function useSocket() {
  const {
    auth: { token },
  } = useContext(AuthContext);

  let socket = undefined;

  useEffect(() => {
    if (!token) return;

    const socket = io(SOCKET_API_URL, {
      auth: {
        token: token,
      },
    });

    _EVENTCODES.map((code) => {
      socket.on(code, (payload) => {
        if (payload.beep) beep();
        EventService.emit(code, payload);
      });
    });

    socket.on("LEAD.UPDATED", (payload) => {
      EventService.emit("LEAD.UPDATED", payload);
    });

    return () => {
      // Remove Event Codes
      _EVENTCODES.map((code) => {
        socket.off(code);
      });
      // Close Socket
      socket.close();
    };
  }, [token]);

  return { socket };
}
