/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useState } from "react";
import { Field, Formik } from "formik";
import { useMutation } from "react-query";
import axios from "axios";
import Endpoints from "../../../config/endpoints";
import { AppButton, AppInput, RowInput } from "../../../components/Form";
import { AuthContext } from "../../../context/AuthContext";

export function Login() {
  const [error, setError] = useState(null);

  const { auth } = useContext(AuthContext);

  // Login Mutation
  const LDM = useMutation((values) => axios.post(`${Endpoints.app.auth}/login`, values), {
    onSuccess: (response) => {
      if (response.token) auth.login(response.token);
    },
    onError: (error) => setError(error),
  });

  return (
    <>
      <Formik initialValues={{ email: "", password: "" }} onSubmit={LDM.mutate}>
        {({ handleSubmit }) => (
          <form className="form w-100" onSubmit={handleSubmit} noValidate>
            <div className="d-flex flex-column px-10">
              <RowInput
                required
                name="email"
                component={AppInput}
                placeholder="Email"
                className="h-50px mb-3 fs-7 text-center"
              />
              <RowInput
                required
                name="password"
                type="password"
                placeholder="Password"
                className="h-50px mb-5 fs-7 text-center"
              />
              <AppButton
                className="btn btn-primary rounded-0 h-50px d-flex justify-content-center align-items-center fs-5"
                title="Sign In"
                loading={LDM.isLoading}
              />
              {error && <div className="alert alert-danger mt-3 rounded-0 h-50px">{error?.message}</div>}
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
