import { Navigate } from "react-router-dom";
import { lazy } from "react";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import DashboardPage from "../modules/dashboard/DashboardPage";
import RouteContainer from "../components/RouteContainer";

const Agents = lazy(() => import("../modules/agents/AgentsPage"));
const AGPage = lazy(() => import("../modules/agent-groups/AGPage"));
const SettingsPage = lazy(() => import("../modules/settings/SettingsPage"));
const BillingPage = lazy(() => import("../modules/billing/BillingPage"));
const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));
const RolePage = lazy(() => import("../modules/roles/RolePage"));
const FieldsPage = lazy(() => import("../modules/fields/FieldsPage"));
const HookPage = lazy(() => import("../modules/hooks/HookPage"));
const LabelsPage = lazy(() => import("../modules/labels/LabelsPage"));
const LeadPage = lazy(() => import("../modules/leads/LeadPage"));
const ClinicPage = lazy(() => import("../modules/clinics/ClinicPage"));
const CategoryPage = lazy(() => import("../modules/categories/CategoryPage"));
const CalendaPage = lazy(() => import("../modules/calendar/CalendarPage"));
const ProfilerPage = lazy(() => import("../modules/profilers/ProfilerPage"));
const TreatmentPage = lazy(() => import("../modules/treatments/TreatmentPage"));
const StaffPage = lazy(() => import("../modules/staff/StaffPage"));
const TaskPage = lazy(() => import("../modules/tasks/TaskPage"));
const LogsPage = lazy(() => import("../modules/logs/LogsPage"));
const RatePage = lazy(() => import("../modules/rates/RatePage"));
const ActionPage = lazy(() => import("../modules/actions/ActionPage"));
const SourcePage = lazy(() => import("../modules/sources/SourcePage"));
const TaxPage = lazy(() => import("../modules/taxs/TaxPage"));
const ErrorPage = lazy(() => import("../modules/error/ErrorPage"));
const FMPage = lazy(() => import("../modules/fields-mapping/FMPage"));
const CMSPage = lazy(() => import("../modules/cms/CMSPage"));
const LevelsPage = lazy(() => import("../modules/levels/LevelsPage"));
const WorkFlowPage = lazy(() => import("../modules/workflow/WorkFlowPage"));
const SupportPage = lazy(() => import("../modules/support/SupportPage"));
const TimelinePage = lazy(() => import("../modules/timelines/TimelinePage"));
const AnalyticsPage = lazy(() => import("../modules/integrations/web/web-analytics/AnalyticsPage"));
const IntegrationPage = lazy(() => import("../modules/integrations/IntegrationPage"));
const SchedulePage = lazy(() => import("../modules/schedules/SchedulePage"));
const MediaTagPage = lazy(() => import("../modules/media-tags/MediaTagPage"));
const ImportPage = lazy(() => import("../modules/import/ImportPage"));
const InternalPage = lazy(() => import("../modules/internals/InternalPage"));
const ReportsPage = lazy(() => import("../modules/reports/ReportsPage"));
const RemindersPage = lazy(() => import("../modules/reminders/RemindersPage"));
const BookingsPage = lazy(() => import("../modules/bookings/BookingsPage"));
const PublicationsPage = lazy(() => import("../modules/publications/PublicationsPage"));
const MetricsPage = lazy(() => import("../modules/metrics/MetricsPage"));
const MessengerPage = lazy(() => import("../modules/messenger/MessengerPage"));
const FormPage = lazy(() => import("../modules/forms/FormPage"));
const MarketingPage = lazy(() => import("../modules/marketing/MarketingPage"));

const paths = [
  { path: "/auth/*", element: <Navigate to="/dashboard" /> },
  { path: "/dashboard/*", element: <DashboardPage /> },
  { path: "/error/*", element: <ErrorPage code={404} /> },
  { path: "/agents/*", element: <Agents /> },
  { path: "/actions/*", element: <ActionPage /> },
  { path: "/sources/*", element: <SourcePage /> },
  { path: "/taxs/*", element: <TaxPage /> },
  { path: "/agent-groups/*", element: <AGPage /> },
  { path: "/roles/*", element: <RolePage /> },
  { path: "/fields/*", element: <FieldsPage /> },
  { path: "/fields-mapping/*", element: <FMPage /> },
  { path: "/hooks/*", element: <HookPage /> },
  { path: "/rates/*", element: <RatePage /> },
  { path: "/labels/*", element: <LabelsPage /> },
  { path: "/leads/*", element: <LeadPage /> },
  { path: "/clinics/*", element: <ClinicPage /> },
  { path: "/categories/*", element: <CategoryPage /> },
  { path: "/calendar/*", element: <CalendaPage /> },
  { path: "/treatments/*", element: <TreatmentPage /> },
  { path: "/staff/*", element: <StaffPage /> },
  { path: "/settings/*", element: <SettingsPage /> },
  { path: "/billing/*", element: <BillingPage /> },
  { path: "/profile/*", element: <ProfilePage /> },
  { path: "/profilers/*", element: <ProfilerPage /> },
  { path: "/integrations/*", element: <IntegrationPage /> },
  { path: "/tasks/*", element: <TaskPage /> },
  { path: "/logs/*", element: <LogsPage /> },
  { path: "/cms/*", element: <CMSPage /> },
  { path: "/levels/*", element: <LevelsPage /> },
  { path: "/workflow/*", element: <WorkFlowPage /> },
  { path: "/support/*", element: <SupportPage /> },
  { path: "/timelines/*", element: <TimelinePage /> },
  { path: "/analytics/*", element: <AnalyticsPage /> },
  { path: "/schedules/*", element: <SchedulePage /> },
  { path: "/import/*", element: <ImportPage /> },
  { path: "/internal/*", service: "scheduler", element: <InternalPage /> },
  { path: "/reports/*", element: <ReportsPage /> },
  { path: "/reminders/*", element: <RemindersPage /> },
  { path: "/bookings/*", service: "booking", element: <BookingsPage /> },
  { path: "/publications/*", element: <PublicationsPage /> },
  { path: "/metrics/*", element: <MetricsPage /> },
  { path: "/media-tags/*", element: <MediaTagPage /> },
  { path: "/messenger/*", title: "T.MESSENGER", element: <MessengerPage /> },
  { path: "/forms/*", title: "T.FORMS", element: <FormPage /> },
  { path: "/marketing/*", title: "T.MARKETING", element: <MarketingPage />, service: "marketing" },
];

export default function PrivateRoutes() {
  return <RouteContainer paths={paths} layout={<MasterLayout />} />;
}
