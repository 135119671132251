import AsideMenuItem from "./AsideMenuItem";
import { useIntl } from "react-intl";
import { useContext } from "react";
import { AuthContext } from "../../../../app/context/AuthContext";
import { ChatContext } from "../../../../app/context/ChatContext";

const _getUnseenBadge = (total) => {
  return <span className="badge rounded-1 badge-primary">{total}</span>;
};

export const menuItems = [
  {
    to: "/dashboard",
    title: "MENU.DASHBOARD",
    fontIcon: "fa fa-bars",
    code: "",
  },
  {
    to: "/workflow",
    title: "MENU.WORKFLOW",
    fontIcon: "fa fa-compass",
    code: "lead:read:basic",
  },
  {
    to: "/leads",
    title: "MENU.LEADS",
    fontIcon: "fa fa-users",
    code: "lead:read:basic",
  },
  {
    to: "/calendar",
    title: "MENU.CALENDAR",
    fontIcon: "fa fa-calendar-alt",
    code: "lead:read:basic",
  },
  {
    to: "/messenger",
    title: "MENU.MESSENGER",
    fontIcon: "fa fa-sms",
  },
  // {
  //   to: "/tasks",
  //   title: "MENU.TASKS",
  //   fontIcon: "fa-tasks",
  //   code: "lead:read",
  // },
  // {
  //   to: "/cms/posts",
  //   title: "MENU.CMS",
  //   fontIcon: "fa-book-reader",
  //   code: "cms:manage",
  // },
];

export function AsideMenuMain() {
  const intl = useIntl();
  const {
    auth: { isAccessible },
  } = useContext(AuthContext);

  const { stats } = useContext(ChatContext);

  const menuItems = [
    {
      to: "/dashboard",
      title: "MENU.DASHBOARD",
      fontIcon: "fa fa-bars",
      code: "",
    },
    {
      to: "/workflow",
      title: "MENU.WORKFLOW",
      fontIcon: "fa fa-compass",
      code: "lead:read:basic",
    },
    {
      to: "/leads",
      title: "MENU.LEADS",
      fontIcon: "fa fa-users",
      code: "lead:read:basic",
    },
    {
      to: "/calendar",
      title: "MENU.CALENDAR",
      fontIcon: "fa fa-calendar-alt",
      code: "lead:read:basic",
    },
    {
      to: "/messenger",
      title: "MENU.MESSENGER",
      fontIcon: "fa fa-sms",
      code: "lead:read:basic",
      notification: stats?.unseen ? _getUnseenBadge(stats?.unseen) : <></>,
    },
  ];

  return (
    <>
      {menuItems
        ?.filter(({ code }) => isAccessible(code))
        .map(({ code, to, title, fontIcon, notification }, index) => {
          return (
            <div key={index}>
              <AsideMenuItem
                notification={notification}
                key={`${code}-${index}`}
                code={code}
                to={to}
                title={intl.formatMessage({ id: title })}
                fontIcon={fontIcon}
              />
            </div>
          );
        })}
    </>
  );
}
