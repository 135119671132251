import { createContext, useEffect, useState } from "react";
import EventService from "../services/event.service";
import notificationService from "../services/notification.service";

const NotificationContext = createContext();
const { Provider } = NotificationContext;

const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const callback = (payload = {}) => {
    notificationService.add(payload);
    return setNotifications((x) => notificationService.getAll());
  };
  const _handleRemoveAll = () => {
    notificationService.removeAll();
    return setNotifications(() => []);
  };

  const _handleSeen = (UUID) => {
    notificationService.seen(UUID);
    return setNotifications(() => notificationService.getAll());
  };

  useEffect(() => {
    EventService.on(["NEW.MESSAGE", "NEW.LEAD"], callback);
    return () => {
      EventService.off(["NEW.MESSAGE", "NEW.LEAD"], callback);
    };
  }, []);

  return (
    <Provider
      value={{
        notifications,
        methods: {
          removeAll: _handleRemoveAll,
          seen: _handleSeen,
        },
      }}
    >
      {children}
    </Provider>
  );
};
export { NotificationContext, NotificationProvider };
