import Select from "react-select";
export default function AppMultiSelect({ field, form: { setFieldValue }, meta, options = [], required, disabled = false, ...props }) {
  const customStyles = {
    control: (props) => ({
      ...props,
      border: "1px solid #e4e6ef",
      borderRadius: 1,
      minHeight: "40px",
    }),
    menu: (props) => ({
      ...props,
      borderRadius: 1,
    }),
    multiValueLabel: (props) => ({
      ...props,
      backgroundColor: "#EEE",
      borderRadius: 1,
      paddingLeft: 10,
      paddingRight: 10,
    }),
    multiValueRemove: (props) => ({
      ...props,
      backgroundColor: "#EEE",
      "&:hover": {
        backgroundColor: "#D9214E",
        opacity: "50%",
        color: "white",
      },
    }),
  };
  return (
    <Select
      instanceId={field.name}
      options={options}
      onChange={(values) =>
        setFieldValue(
          field.name,
          values?.map(({ value }) => value)
        )
      }
      isOptionDisabled={required ? ({ value }) => !Boolean(value) : undefined}
      value={options ? options.filter((option) => field.value?.includes(option.value)) : ""}
      isMulti
      styles={customStyles}
      {...props}
      isDisabled={disabled}
    />
  );
}
