import { useContext } from "react";
import { AppContext } from "../../../../app/context/AppContext";
import { AppLayoutContext } from "../../../../app/context/LayoutContext";
import { KTSVG } from "../../../helpers";
import HeaderUserMenu from "../../../partials/layout/header-menus/HeaderUserMenu";

export default function Topbar() {
  const { CM } = useContext(AppContext);
  const { profile } = CM;
  const { call, notifications } = useContext(AppLayoutContext);
  return (
    <div className="d-flex align-items-center">
      {/* Notifications */}
      <div className="d-flex align-items-center ms-1 ms-lg-3" onClick={() => notifications.show()}>
        <div className="btn btn-icon btn-active-light-primary btn-custom position-relative w-30px h-30px w-md-40px h-md-40px">
          <KTSVG path="/media/icons/duotune/general/gen007.svg" className="svg-icon-1" />
        </div>
      </div>
      {/* Call Center */}
      {profile.services?.includes("call") && (
        <div className="d-flex align-items-center ms-1 ms-lg-3" onClick={() => call.show()}>
          <div className="btn btn-icon btn-active-light-primary btn-custom position-relative w-30px h-30px w-md-40px h-md-40px">
            <KTSVG path="/media/icons/duotune/electronics/elc002.svg" className="svg-icon-1" />
            {/* <span className="bullet bullet-dot bg-danger h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink"></span> */}
          </div>
        </div>
      )}
      {/* PM */}
      <div className="ms-1 ms-lg-3">
        <HeaderUserMenu />
      </div>
    </div>
  );
}
