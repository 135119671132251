import React from "react";

import { AsideMenuMain } from "./AsideMenuMain";
import clsx from "clsx";

type Props = {
  asideMenuCSSClasses: string[];
};

const AsideMenu: React.FC<Props> = ({ asideMenuCSSClasses }) => {
  return (
    <div
      className={clsx(
        "menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500",
        asideMenuCSSClasses.join(" ")
      )}
    >
      <AsideMenuMain />
    </div>
  );
};

export { AsideMenu };
