import { APP_BASE_URL, CMS_BASE_URL, ANALYTICS_BASE_URL, ACADEMY_BASE_URL, CDN_BASE_URL, SMS_BASE_URL } from ".";

const ADMIN = `${APP_BASE_URL}/admin`;
const APP = `${APP_BASE_URL}/app`;
const CMS = `${CMS_BASE_URL}/admin`;
const ACADEMY = `${ACADEMY_BASE_URL}/app`;
const ANALYTICS = `${ANALYTICS_BASE_URL}/admin`;
const SMS = `${SMS_BASE_URL}/admin`;
const CDN = CDN_BASE_URL;

const Endpoints = {
  user: `${ADMIN}/users`,
  action: `${ADMIN}/actions`,
  asset: `${ADMIN}/assets`,
  brand: `${ADMIN}/brands`,
  material: `${ADMIN}/materials`,
  inventory: `${ADMIN}/inventory`,
  operation: `${ADMIN}/operations`,
  group: `${ADMIN}/groups`,
  stats: {
    user: `${ADMIN}/stats/user`,
    company: `${ADMIN}/stats/company`,
  },
  category: `${ADMIN}/categories`,
  callerID: `${ADMIN}/caller-ids`,
  clinic: `${ADMIN}/clinics`,
  company: `${ADMIN}/companies`,
  contact: `${ADMIN}/contacts`,
  csv: `${ADMIN}/csv`,
  field: `${ADMIN}/fields`,
  files: `${ADMIN}/files`,
  form: `${ADMIN}/forms`,
  facebook: {
    pages: `${ADMIN}/facebook/pages`,
    forms: `${ADMIN}/facebook/forms`,
    authorize: `${ADMIN}/facebook/authorize`,
  },
  hook: `${ADMIN}/hooks`,
  invoice: `${ADMIN}/invoices`,
  integration: {
    facebook: `${ADMIN}/integrations/facebook`,
    alotech: `${ADMIN}/integrations/alotech`,
    twilio: `${ADMIN}/integrations/twilio`,
    telnyx: `${ADMIN}/integrations/telnyx`,
    kobikom: `${ADMIN}/integrations/kobikom`,
    gmail: `${ADMIN}/integrations/gmail`,
    gateway: `${ADMIN}/integrations/gateways`,
    whatsapp: `${ADMIN}/integrations/whatsapp`,
  },
  marketing: {
    sms: {
      contact: `${SMS}/contacts`,
      group: `${SMS}/groups`,
      link: `${SMS}/links`,
      pool: `${SMS}/pools`,
    },
  },
  duty: `${ADMIN}/duties`,
  label: `${ADMIN}/labels`,
  lead: `${ADMIN}/leads`,
  cdn: `${CDN}`,
  media: `${ADMIN}/medias`,
  public: `${APP}/public`,
  metric: `${ADMIN}/metrics`,
  message: `${ADMIN}/messages`,
  misc: `${ADMIN}/misc`,
  numara: `${ADMIN}/numaras`,
  note: `${ADMIN}/notes`,
  profiler: `${ADMIN}/profilers`,
  phone: `${ADMIN}/phones`,
  role: `${ADMIN}/roles`,
  rate: `${ADMIN}/rates`,
  schedule: `${ADMIN}/schedules`,
  source: `${ADMIN}/sources`,
  staff: `${ADMIN}/staffs`,
  tax: `${ADMIN}/taxs`,
  task: `${ADMIN}/tasks`,
  treatment: `${ADMIN}/treatments`,
  twilio: `${ADMIN}/twilio`,
  whois: `${ADMIN}/whois`,
  app: {
    auth: `${APP}/auth`,
    media: `${APP}/medias`,
  },
  log: `${ADMIN}/logs`,
  report: `${ADMIN}/reports`,
  call: `${ADMIN}/calls`,
  log: {
    call: `${ADMIN}/logs/call`,
    sms: `${ADMIN}/logs/sms`,
    whatsapp: `${ADMIN}/logs/whatsapp`,
    facebook: `${ADMIN}/logs/facebook`,
    access: `${ADMIN}/logs/access`,
  },
  translation: `${ADMIN}/translations`,
  permisson: `${ADMIN}/permissions`,
  notification: `${ADMIN}/notifications`,
  WPage: `${ADMIN}/web-pages`,
  calendar: `${ADMIN}/calendar`,
  resource: `${ADMIN}/resources`,
  map: `${ADMIN}/maps`,
  match: `${ADMIN}/matches`,
  mediaTag: `${ADMIN}/media-tags`,
  messageTemplate: `${ADMIN}/message-templates`,
  domain: `${ADMIN}/domains`,
  level: `${ADMIN}/levels`,
  event: `${ADMIN}/events`,
  ticket: `${ADMIN}/tickets`,
  email: `${ADMIN}/emails`,
  timelineTemplate: `${ADMIN}/timeline-templates`,
  instruction: `${ADMIN}/instructions`,
  publication: {
    document: `${ADMIN}/publications/documents`,
    template: `${ADMIN}/publications/templates`,
    verify: `${ADMIN}/publications/verify`,
  },
  academy: {
    nugget: `${ACADEMY}/nuggets`,
    snack: `${ACADEMY}/snacks`,
    post: `${ACADEMY}/posts`,
  },
  cms: {
    post: `${CMS}/posts`,
    category: `${CMS}/categories`,
    treatment: `${CMS}/treatments`,
    pack: `${CMS}/packs`,
    slider: `${CMS}/sliders`,
    media: `${CMS}/medias`,
    gallery: `${CMS}/galleries`,
    bfa: `${CMS}/bfas`,
    testimonial: `${CMS}/testimonials`,
    faq: `${CMS}/faqs`,
    media: `${CMS}/medias`,
  },
  analytics: {
    visitor: `${ANALYTICS}/visitors`,
    field: `${ANALYTICS}/fields`,
  },
  booking: {
    driver: `${ADMIN}/booking/drivers`,
    hotel: `${ADMIN}/booking/hotels`,
    transfer: `${ADMIN}/booking/transfers`,
    reservation: `${ADMIN}/booking/reservations`,
    place: `${ADMIN}/booking/places`,
  },
};

export default Endpoints;
