import { Field } from "formik";
import { useIntl } from "react-intl";
import AppInput from "./AppInput";
export default function RowInput({ name, label, icon, locale = undefined, component = AppInput, placeholder, ...props }) {
  const intl = useIntl();

  return (
    <div className="row g-3">
      {label && (
        <div className="col-4 d-flex align-items-center">
          <label className={`${props?.required && `required`} fs-7 fw-bold text-capitalize text-truncate text-gray-800`}>
            {icon && <span className={`${icon} text-muted me-3`} />}
            {intl.formatMessage({ id: Boolean(label) ? label : "-" })} {locale ? `(${locale})` : ``}
          </label>
        </div>
      )}
      <div className={label ? `col-8` : `col-12`}>
        <Field
          name={name}
          placeholder={intl.formatMessage({ id: Boolean(placeholder) ? placeholder : name ?? "-" })}
          component={component}
          autoComplete="off"
          {...props}
        />
      </div>
    </div>
  );
}
