import { useContext } from "react";
import AppDrawer from "../AppDrawer";
import AppCard from "../../AppCard";
import { AppLayoutContext } from "../../../context/LayoutContext";
import { CloseButton } from "react-bootstrap";
import NotificationItem from "./components/NotificatioItem";
import { AppButton } from "../../Form";
import { NotificationContext } from "../../../context/NotificationContext";

export default function NotificationsDrawer({}) {
  const { notifications: ND } = useContext(AppLayoutContext);
  const {
    notifications,
    methods: { removeAll },
  } = useContext(NotificationContext);
  return (
    <AppDrawer show={ND.enabled} onClose={() => ND.close()} scrollable>
      <AppCard className="w-100" title="T.NOTIFICATIONS" toolbar={<CloseButton onClick={() => ND.close()} />}>
        <div className="card-body" style={{ position: "relative" }}>
          <div className="hover-scroll-overlay-y w-100 h-100" style={{ position: "absolute", top: 0, bottom: 0, left: 0 }}>
            {notifications?.map((notification, _index) => (
              <NotificationItem {...notification} key={_index} />
            ))}
          </div>
        </div>
        <div className="card-footer d-flex align-items-center justify-content-start">
          <AppButton className="btn btn-light-danger" title="BTN.CLEAR_ALL" onClick={() => removeAll()} />
        </div>
      </AppCard>
    </AppDrawer>
  );
}
