import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import AsideDefault from "./components/aside/AsideDefault";
import { Footer } from "./components/Footer";
import { HeaderWrapper } from "./components/header/HeaderWrapper";
import { PageDataProvider } from "./core";
import { MenuComponent } from "../assets/ts/components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import AppDialog from "../../app/components/AppDialog";
import { CallProvider } from "../../app/context/CallContext";
import DrawersContainer from "../../app/components/drawers/DrawersContainer";
import { ChatProvider } from "../../app/context/ChatContext";
import { NotificationProvider } from "../../app/context/NotificationContext";

const MasterLayout = () => {
  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);

  return (
    <PageDataProvider>
      <NotificationProvider>
        <CallProvider>
          <ChatProvider>
            <div className="page d-flex flex-row flex-column-fluid">
              <AsideDefault />
              <div className="wrapper d-flex flex-column flex-row-fluid" style={{ height: "100vh" }}>
                <HeaderWrapper />
                <div id="kt_content" className="d-flex flex-column flex-column-fluid px-3 pt-3" style={{ marginTop: "50px" }}>
                  <Outlet />
                  <ToastContainer />
                  <DrawersContainer />
                </div>
                <Footer />
              </div>
            </div>
            <AppDialog />
          </ChatProvider>
        </CallProvider>
      </NotificationProvider>
    </PageDataProvider>
  );
};

export { MasterLayout };
