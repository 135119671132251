/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext } from "react";
import { AppContext } from "../../../../app/context/AppContext";
import LocalMedia from "../../../../app/components/LocalMedia";
import { forwardRef } from "react";
import { Dropdown } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../helpers";
import { useLang, setLanguage } from "../../../i18n/Metronici18n";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../../app/context/AuthContext";

const languages = [
  {
    code: "en",
    name: "English",
    flag: toAbsoluteUrl("/media/flags/united-states.svg"),
  },
  {
    code: "tr",
    name: "Turkish",
    flag: toAbsoluteUrl("/media/flags/turkey.svg"),
  },
];

const CustomToggle = forwardRef(({ onClick, thumbnail }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className="cursor-pointer"
  >
    <div className="symbol symbol-25px symbol-circle">
      <LocalMedia mediaID={thumbnail} />
    </div>
  </div>
));

export default function HeaderUserMenu() {
  const {
    auth: { logout },
  } = useContext(AuthContext);

  const active = useLang();

  const { PM } = useContext(AppContext);

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} thumbnail={PM.profile?.thumbnail} />
      <Dropdown.Menu className="rounded-0 shadow-none mt-3 p-0 border" style={{ width: "250px" }}>
        <div className="p-0">
          <div className="d-flex flex-column align-items-center bg-light justify-content-center py-10">
            <div className="symbol symbol-50px symbol-circle">
              <LocalMedia mediaID={PM.profile?.thumbnail} />
            </div>
            <span className="fw-bold d-flex align-items-center fs-6 mt-3">{PM.profile?.name}</span>
            <a className="text-muted text-hover-primary fs-7">{PM.profile.email}</a>
          </div>
        </div>
        <Dropdown.Item className="p-0 bg-hover-light">
          <Link to="/profile">
            <div className="p-3 text-gray-600 text-hover-primary d-flex align-items-center justify-content-between">
              <span>Profile Settings</span>
              <span className="fa fa-cog" />
            </div>
          </Link>
        </Dropdown.Item>
        <div className="separator"></div>
        <div className="p-3 d-flex align-items-center justify-content-start">
          {languages
            .filter(({ code }) => code !== active)
            .map((l, _index) => (
              <div
                className={`badge badge-light rounded-0 d-flex align-items-center me-2 cursor-pointer bg-hover-light text-muted text-hover-gray-700`}
                key={_index}
                onClick={() => setLanguage(l.code)}
              >
                <span className="symbol symbol-20px me-3">
                  <img className="rounded-circle" src={l.flag} alt="metronic" />
                </span>
                {l.name}
              </div>
            ))}
        </div>
        <div className="separator"></div>
        <div onClick={logout} className="bg-hover-light py-5 text-center">
          <span className="text-danger">Sign Out</span>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
