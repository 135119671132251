import RouteContainer from "../../components/RouteContainer";
import { lazy, useContext } from "react";
import AppToolbar from "../../components/AppToolbar";
import { AuthContext } from "../../context/AuthContext";

const CompanyDashboard = lazy(() => import("./CompanyDashboard"));
const AgentDashboard = lazy(() => import("./AgentDashboard"));

export default function DashboardPage() {
  const {
    auth: { user },
  } = useContext(AuthContext);
  const paths = [
    {
      path: "/",
      title: "Dashboard",
      element: user.type === "company" || user.dashboard === "company" ? <CompanyDashboard /> : <AgentDashboard />,
    },
  ];
  return (
    <div className="d-flex flex-column">
      <AppToolbar />
      <RouteContainer paths={paths} />
    </div>
  );
}
