import DatePicker, { registerLocale } from "react-datepicker";
import tr from "date-fns/locale/tr";
import "react-datepicker/dist/react-datepicker.css";
import { forwardRef, useContext } from "react";
import { AppContext } from "../../context/AppContext";
registerLocale("tr", tr);

const DateTimePickerInput = forwardRef(({ value, onClick }, ref) => (
  <div className="h-40px border border-gray-300 d-flex align-items-center w-100" onClick={onClick} ref={ref}>
    <div className="ps-3 pe-5 w-100 text-gray-600 text-hover-gray-700 h-100 d-flex align-items-center cursor-pointer">{value}</div>
    <div className="border-gray-300 text-muted cursor-pointer text-hover-aws-text px-5 h-100 d-flex align-items-center">
      <span className="fa fa-calendar" />
    </div>
  </div>
));

const _getFormat = (format, time = false) => {
  let newFormat = format?.split(" ")[0]?.replace("YYYY", "yyyy").replace("DD", "dd");
  return time ? `${newFormat} HH:m:s` : newFormat;
};

export default function AppDateTimePicker({ form, field, time = false, ...props }) {
  const { PM } = useContext(AppContext);
  return (
    <div {...props}>
      <DatePicker
        disabled={props.disabled}
        selected={field.value ? new Date(field.value) : new Date()}
        onChange={(value) => form.setFieldValue(field.name, time ? value.toISOString() : value.toISOString().split("T")[0])}
        locale="tr"
        showTimeSelect={time}
        customInput={<DateTimePickerInput value={field.value} />}
        dateFormat={_getFormat(PM.profile?.default?.dateFormat ?? "yyyy-MM-dd h:m:s", time)}
      />
    </div>
  );
}
