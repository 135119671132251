import { createContext } from "react";
import useCompany from "../../hooks/useCompany";
import useGlobal from "../../hooks/useGlobal";
import useProfile from "../../hooks/useProfile";
import useRate from "../../hooks/useRate";
import useSocket from "../../hooks/useSocket";
import Spinner from "../components/Spinner";

const AppContext = createContext();
const { Provider } = AppContext;

const LoadingScreen = () => {
  return (
    <div className="h-100 w-100 position-absolute d-flex flex-column align-items-center justify-content-center">
      <Spinner />
      <span className="text-muted mt-5">Downloading resources ... </span>
    </div>
  );
};

const AppConfigProvider = ({ children }) => {
  const PM = useProfile();
  const GM = useGlobal();
  const CM = useCompany();
  useSocket(); // Initiate SocketIO
  return (
    <Provider
      value={{
        PM, // Profile Information
        RM: useRate(), // Currencies
        CM, // Company Details
        GM, // Global Values
      }}
    >
      {GM.isLoading || CM.isLoading || PM.isLoading ? <LoadingScreen /> : children}
    </Provider>
  );
};
export { AppContext, AppConfigProvider };
