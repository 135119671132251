import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import Endpoints from "../config/endpoints";
import { AppContext } from "./AppContext";
import EventService from "../services/event.service";

const ChatContext = createContext();
const { Provider } = ChatContext;

const ChatProvider = ({ children }) => {
  const { CM } = useContext(AppContext);

  const [stats, setStats] = useState({ unseen: 0, seen: new Set() });

  const _isEnabled = CM.services?.messenger;

  // Get Conversation Notifications
  const GCM = useMutation(() => axios.get(`${Endpoints.user}/me/conversations/stats`), {
    onSuccess: (response) => {
      const { unseen = 0 } = response.stats ?? {};
      setStats({ unseen, seen: new Set() }); // Reset seen records upon update
      return;
    },
  });

  useEffect(() => {
    // Initialy Get Notifications
    if (_isEnabled) GCM.mutate();

    // Update Notifications
    const callback = () => GCM.mutate();
    if (_isEnabled) EventService.on(["NEW.MESSAGE"], callback);
    return () => {
      EventService.off(["NEW.MESSAGE"], callback);
    };
  }, []);

  return (
    <Provider
      value={{
        _isEnabled,
        stats,
        methods: {
          checkSaw: (conversationID) => setStats((x) => ({ ...x, unseen: x.unseen > 0 ? x.unseen - 1 : 0, seen: x.seen?.add(conversationID) })),
          isAlreadySeen: (conversationID) => stats.seen?.has(conversationID),
        },
      }}
    >
      {children}
    </Provider>
  );
};
export { ChatContext, ChatProvider };
