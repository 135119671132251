import { useLoadScript } from "@react-google-maps/api";
import { GOOGLE_MAPS_API_KEY } from "../app/config";
import { useState } from "react";
export default function useGoogleMap() {
  const [libraries] = useState(["places"]);
  const { isLoaded } = useLoadScript({ googleMapsApiKey: GOOGLE_MAPS_API_KEY, libraries });
  const getDirections = async (origin, destination) => {
    if (!origin || !destination) return { error: "Missing Origin/Destination" };
    if (!window.google) return { error: "Google Maps is not loaded yet" };
    try {
      const directionsService = new window.google.maps.DirectionsService();
      const directions = await directionsService.route({
        origin,
        destination,
        travelMode: window.google.maps.TravelMode.DRIVING,
      });
      return { data: { directions, distance: directions.routes[0].legs[0].distance.text, duration: directions.routes[0].legs[0].duration.text } };
    } catch (error) {
      return { error };
    }
  };
  return { isReady: isLoaded, getDirections };
}
