import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import PrivateRoutes from "./PrivateRoutes";
import { Logout } from "../modules/auth/components/Logout";
import AuthPage from "../modules/auth/AuthPage";
import { App } from "../App";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";

const AppRoutes = () => {
  const {
    auth: { isLogedIn },
  } = useContext(AuthContext);
  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route element={<App />}>
          <Route path="logout" element={<Logout />} />
          {isLogedIn ? (
            <>
              <Route path="/*" element={<PrivateRoutes />} />
              <Route index element={<Navigate to="/dashboard" />} />
            </>
          ) : (
            <>
              <Route path="auth/*" element={<AuthPage />} />
              <Route path="*" element={<Navigate to="/auth" />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
