import { useRef } from "react";
import toggleInclusion from "../../utilities/toggleInclusion";

const TagBagde = ({ value, onClick }) => {
  return (
    <div
      className="bg-white d-flex align-items-center bg-light text-gray-600 mb-1 py-1 px-2 me-1 rounded-0 cursor-pointer text-hover-danger fs-8 fw-normal"
      onClick={onClick}
    >
      <span>{value}</span>
      <span className="fa fa-times fs-9 ms-2 px-2" />
    </div>
  );
};
export default function AppTagInput({ field, form: { setFieldValue }, meta, ...props }) {
  const inputRef = useRef();
  const { value } = field;
  const handleValue = (text) => setFieldValue(field.name, toggleInclusion(value ?? [], text));
  const handleKey = (event) => {
    if ((event.key === "Enter" || event.key === "Tab") && inputRef.current.value) {
      handleValue(inputRef.current.value);
      inputRef.current.value = "";
    }
  };

  return (
    <div className={`d-flex flex-column ${props?.className}`} style={{ border: "1px solid #e4e6ef" }}>
      <div className="px-3">
        <input
          onKeyDown={handleKey}
          ref={inputRef}
          placeholder={props.placeholder}
          className="px-0 form-control form-control-sm rounded-0 form-control-transparent"
          rows={1}
        />
      </div>
      {value?.length > 0 && (
        <div className="d-flex aligin-items-center px-1 pt-1 flex-wrap" style={{ borderTop: "1px solid #e4e6ef" }}>
          {value?.map((value, _index) => (
            <TagBagde value={value} key={_index} onClick={() => handleValue(value)} />
          ))}
        </div>
      )}
    </div>
  );
}
