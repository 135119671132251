import axios from "axios";
import { useContext, useEffect } from "react";
import { useMutation } from "react-query";
import Endpoints from "../app/config/endpoints";
import { AuthContext } from "../app/context/AuthContext";

export default function useRate() {
  const {
    auth: { token },
  } = useContext(AuthContext);

  // Get Exchange Rates Mutation
  const GRM = useMutation(() => axios.get(`${Endpoints.company}/rates`));

  // Get Rate Value
  const getRate = (_id) => GRM.data?.find((x) => x._id === _id);

  // Calculate Price
  const calculate = (value = 0, fromCode, toCode) => {
    if (fromCode === toCode) return value;
    const fromRate = getRate(fromCode) ?? { value: 1 };
    const toRate = getRate(toCode) ?? { value: 0 };
    const coeff = toRate.value / fromRate.value;
    return Math.ceil(coeff * value * 100) / 100;
  };

  // Get Rates
  useEffect(() => {
    if (token) GRM.mutate();
  }, [token]);

  return { methods: { getRate, calculate }, rates: GRM.data ?? [] };
}
