import axios from "axios";
import { useContext, useEffect } from "react";
import { useMutation } from "react-query";
import Endpoints from "../app/config/endpoints";
import { AuthContext } from "../app/context/AuthContext";

export default function useProfile() {
  const GPM = useMutation(() => axios.get(`${Endpoints.user}/me/profile`));

  const {
    auth: { token },
  } = useContext(AuthContext);

  useEffect(() => {
    if (token) GPM.mutate();
  }, [token]);

  const refresh = () => GPM.mutate();

  return { profile: GPM.data ?? {}, methods: { refresh }, isLoading: GPM.isLoading };
}
