import { checkIsActive } from "../../../helpers";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { memo } from "react";

const AsideMenuItem = ({ to, title, fontIcon, notification }) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);

  return (
    <Link to={to}>
      <div
        className={`h-50px fs-7 d-flex align-items-center justify-content-between h-100 ${
          isActive ? "bg-gray-200 text-primary" : "text-gray-700 text-hover-primary"
        } rounded-0 w-100 px-5`}
      >
        <div className="d-flex align-items-center">
          {fontIcon && <span className={`me-3 ${fontIcon}`}></span>}
          <span className="text-capitalize fw-bold">{title}</span>
        </div>
        {notification}
      </div>
    </Link>
  );
};

export default memo(AsideMenuItem);
