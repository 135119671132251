import axios from "axios";
import { useContext, useEffect } from "react";
import { useMutation } from "react-query";
import Endpoints from "../app/config/endpoints";
import { AuthContext } from "../app/context/AuthContext";

export default function useCompany() {
  const GCM = useMutation(() => axios.get(`${Endpoints.company}/basic`));

  const {
    auth: { token },
  } = useContext(AuthContext);

  useEffect(() => {
    if (token) GCM.mutate();
  }, [token]);

  const refresh = () => GCM.mutate();

  // Company Profile
  const profile = GCM.data ?? {};

  // Company Services
  const services = {
    messenger: ["facebook", "whatsapp"].some((service) => profile?.services?.includes(service)),
    call: profile?.services?.includes("call"),
    scheduler: profile?.services?.includes("scheduler"),
    booking: profile?.services?.includes("booking"),
    marketing: profile?.services?.includes("marketing"),
  };

  // Service Accessible
  const isEnabled = (codes) => {
    if (!codes) return true;
    if (Array.isArray(codes)) return codes?.some((code) => services?.[code]);
    else return services?.[codes];
  };

  return { profile, services, methods: { refresh }, isEnabled, isLoading: GCM.isLoading };
}
