import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";

type Props = {
  to: string;
  title: string;
  icon?: string;
  hasArrow?: boolean;
  hasBullet?: boolean;
};

const MenuItem: React.FC<Props> = ({ to, title, icon, hasArrow = false }) => {
  return (
    <div className="menu-item me-lg-1">
      <Link
        className={clsx("menu-link", {
          active: false,
        })}
        to={to}
      >
        {icon && <span className={`text-muted me-3 ${icon}`}></span>}
        <span className="menu-title">{title}</span>
        {hasArrow && <span className="menu-arrow"></span>}
      </Link>
    </div>
  );
};

export { MenuItem };
