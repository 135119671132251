import { useNavigate, useLocation } from "react-router-dom";
import AppTitle from "./AppTitle";
import { AppButton } from "./Form";
import Spinner from "./Spinner";

export default function AppToolbar({ toolbar, loading }) {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="toolbar border-bottom border-gray-300 bg-aws-bg">
      <div className="d-flex align-items-center px-5 justify-content-between w-100">
        <div className="flex-grow-1 d-flex align-items-center">
          {location?.pathname?.split("/")?.length > 2 && (
            <AppButton className="btn btn-circle btn-light me-3" icon="fa fa-chevron-left" onClick={() => navigate(-1)} />
          )}
          <AppTitle />
        </div>
        {loading ? <Spinner size="sm" className="mx-5" /> : <div className="d-flex align-items-center">{toolbar}</div>}
      </div>
    </div>
  );
}
