import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

export default function AppButton({
  loading,
  disabled = false,
  title,
  tooltip = "",
  icon,
  badge,
  className = "btn btn-primary",
  href,
  onClick = () => {},
  ...props
}) {
  const intl = useIntl();
  const navigate = useNavigate();
  const leftSideEnabled = loading || icon;
  const rightSideEnabled = title || (!title && !icon);
  const marginBetween = leftSideEnabled && rightSideEnabled ? "me-3" : "";
  return (
    <button
      className={`${className} btn-sm rounded-0 text-nowrap position-relative`}
      {...props}
      onClick={() => (href ? navigate(href) : onClick())}
      disabled={loading || disabled}
      title={tooltip ? intl.formatMessage({ id: tooltip }) : ""}
    >
      <div className="d-flex aligin-items-center justify-content-between">
        <div className="d-flex align-items-center">
          {leftSideEnabled && (
            <div className={marginBetween}>
              {loading && <span className="spinner-border spinner-border-sm align-middle" />}
              {!loading && <span className={`${icon}`} />}
            </div>
          )}
          {rightSideEnabled && (
            <div>
              {title && <span>{intl.formatMessage({ id: title })}</span>}
              {!title && <span>{intl.formatMessage({ id: "BTN.SAVE" })}</span>}
            </div>
          )}
        </div>
        {badge}
      </div>
    </button>
  );
}
