import moment from "moment-timezone";

const NOTIF_PAYLOAD = {
  UUID_1: {
    display: { name: "", thumbnail: "" }, // How Notification P
    code: "NEW.MESSAGE",
    updatedAt: "",
    payload: { leadID: "", conversationID: "" },
    count: 1,
    seen: false,
  },
};

const NOTIF_DATA_DISPLAYED = [
  {
    UUID: "UUID_1",
    display: { name: "", thumbnail: "" },
    code: "code_a",
    count: 1,
    seen: false,
    updatedAt: "",
    payload: { leadID: "", conversationID: "" },
  },
];

// Get All Notifications
const getAll = () => {
  try {
    if (!localStorage.getItem("notifications")) return [];
    const localData = JSON.parse(localStorage.getItem("notifications"));
    const notifications = Object.keys(localData).map((UUID) => ({ UUID, ...localData[UUID] }));
    // Sort By Data Updated
    notifications.sort((a, b) => moment(a.updatedAt).isBefore(moment(b.updatedAt)));
    return notifications;
  } catch (error) {
    return [];
  }
};

// Seen Single Notification
const seen = (UUID) => {
  const localData = localStorage.getItem("notifications") ? JSON.parse(localStorage.getItem("notifications")) : {};
  if (!localData[UUID]) return;
  localData[UUID] = { ...localData[UUID], seen: true, count: 0 };
  return localStorage.setItem("notifications", JSON.stringify(localData));
};

// Remove All Notifications
const removeAll = () => localStorage.removeItem("notifications");

// Add Notification
const add = (_payload = {}) => {
  const { UUID, code, updatedAt, display, ...payload } = _payload;
  if (!UUID) return;
  const localData = localStorage.getItem("notifications") ? JSON.parse(localStorage.getItem("notifications")) : {};
  if (localData[UUID]) localData[UUID] = { ...localData[UUID], seen: false, count: (localData[UUID]?.count ?? 0) + 1, updatedAt };
  else localData[UUID] = { display, code, payload, count: 1, seen: false, updatedAt };
  localStorage.setItem("notifications", JSON.stringify(localData));
  return getAll();
};

const notificationService = { getAll, removeAll, add, seen };
export default notificationService;
