import { useIntl } from "react-intl";

export default function AppCard({
  title = "-",
  toolbar,
  children,
  className,
  style = {},
  locale = true,
  guide = false,
  loading = false,
  icon,
  accessory,
  onHeaderClick = () => {},
  ...props
}) {
  const intl = useIntl();
  return (
    <div className={`card rounded-0  ${className}`} style={{ backgroundColor: "#fdfdfd", borderColor: "#d5d9d9", ...style }} {...props}>
      <div
        onClick={onHeaderClick}
        className={`card-header d-flex flex-nowrap align-items-center ${(loading || !children) && `border-0`} border-gray-300`}
        style={{ backgroundColor: "#FAFAFA" }}
      >
        <div className="card-title text-truncate text-gray-700 text-uppercase fs-7 fw-semibold d-flex align-items-center" style={{ letterSpacing: "0.04em" }}>
          {icon && <div className="me-3">{icon}</div>}
          {locale ? intl.formatMessage({ id: title }) : title}
          {accessory && <div className="ms-3">{accessory}</div>}
          {guide && <span className="fa fa-question-circle rounded-full ms-3 text-muted text-hover-primary cursor-pointer" />}
        </div>
        <div className="card-toolbar">{loading ? <span className="spinner-border spinner-border-sm" /> : toolbar}</div>
      </div>
      {!loading && children}
    </div>
  );
}
