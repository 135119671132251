import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
import Endpoints from "../../../config/endpoints";
import AppButton from "../AppButton";
import { useMutation } from "react-query";
import LocalMedia from "../../LocalMedia";
import AppIcon from "../../AppIcon";
import toast from "../../../helpers/toast";
import Spinner from "../../Spinner";
import toggleInclusion from "../../../utilities/toggleInclusion";

export default function MediaPickerModal({ show, onClose, leadID, multiple = false, onSelect, defaultSelected = [] }) {
  const intl = useIntl();
  const [selected, setSelected] = useState([]);
  const uploadRef = useRef(null);

  // Get Medias Mutation
  const GMM = useMutation((queries) =>
    axios.get(leadID ? `${Endpoints.lead}/${leadID}/medias` : Endpoints.media, { params: queries })
  );

  // Upload Media Mutation
  const UMM = useMutation(
    (formData) =>
      axios.post(leadID ? `${Endpoints.lead}/${leadID}/medias` : Endpoints.media, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
    {
      onSuccess: () => GMM.mutate(),
      onError: () => toast.warning("Failed to upload thumbnail"),
    }
  );

  // Handle Upload File
  const handleUploadMedia = (e) => {
    const formData = new FormData();
    for (let index = 0; index < e.target.files.length; index++) {
      formData.append("media", e.target.files[index]);
    }
    UMM.mutate(formData);
    uploadRef.current.value = null;
  };

  useEffect(() => {
    GMM.mutate();
    setSelected(defaultSelected);
  }, []);

  return (
    <Modal centered animation={false} size="lg" show={show} onHide={onClose}>
      <Modal.Header>
        <Modal.Title className="d-flex align-items-center justify-content-between w-100">
          <span>{intl.formatMessage({ id: "T.SELECT_MEDIA" })}</span>
          {GMM.isLoading && <Spinner size="sm" />}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="hover-scroll-overlay-y p-0" style={{ height: "50vh" }}>
        <input type="file" disabled={UMM.isLoading} hidden ref={uploadRef} onChange={handleUploadMedia} />
        <div className="p-3 border-bottom border-gray-300 bg-gray-100">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const search = e.target?.search?.value;
              return GMM.mutate({ search });
            }}
          >
            <input
              name="search"
              autoComplete="off"
              className="form-control h-40px rounded-none"
              type="text"
              placeholder="Search Medias"
            />
          </form>
        </div>
        {GMM.data?.map((media, _index) => {
          const _type = media.mime?.split("/")[0];
          return (
            <div
              className="d-flex align-items-center justify-content-between px-5 py-3 pe-10 text-gray-100 text-hover-gray-400 cursor-pointer border-bottom"
              onClick={() => setSelected((x) => (multiple ? toggleInclusion(x, media._id) : [media._id]))}
              key={_index}
            >
              <div className="d-flex align-items-center w-75">
                {_type === "image" ? (
                  <div className="symbol symbol-75px">
                    <LocalMedia mime={media.mime} mediaID={media._id} />
                  </div>
                ) : _type === "video" ? (
                  <LocalMedia mime={media.mime} mediaID={media._id} className="mw-50" style={{ maxHeight: "300px" }} />
                ) : _type === "audio" ? (
                  <LocalMedia mime={media.mime} mediaID={media._id} />
                ) : (
                  <AppIcon code={media.mime} />
                )}
                <span className="text-gray-700 fs-7 ms-5 text-truncate">{media?.name}</span>
              </div>
              <span
                className={`${selected?.includes(media._id) ? `fa fa-check-circle text-primary` : `fa fa-circle`}`}
              />
            </div>
          );
        })}
      </Modal.Body>
      <Modal.Footer className="d-flex align-items-center justify-content-between">
        <AppButton
          className="btn btn-light"
          icon="fa fa-upload"
          title="BTN.UPLOAD"
          loading={UMM.isLoading}
          onClick={() => uploadRef.current?.click()}
        />
        <AppButton title="BTN.SELECT" disabled={selected.length === 0} onClick={() => onSelect(selected)} />
      </Modal.Footer>
    </Modal>
  );
}
