import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { useMutation } from "react-query";
import _axios from "../../setup/_axios";
import Endpoints from "../config/endpoints";
import AuthorizeLoading from "../modules/auth/components/AuthorizeLoading";

const AuthContext = createContext();
const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [user, setUser] = useState(undefined);
  const [loading, setLoading] = useState(true);

  const login = (token) => {
    setToken(token);
    localStorage.setItem("token", token);
  };

  const logout = () => {
    setToken(undefined);
    localStorage.removeItem("token");
    window.location.reload();
    return;
  };

  const isAccessible = (code) => {
    if (!code || user?.type === "company") return true;
    return Boolean(user?.accessibleCodes?.includes(code));
  };

  // Verify User Mutation
  const VUM = useMutation((token) => axios.post(`${Endpoints.app.auth}/verify`, { token }), {
    onSuccess: (response) => {
      setUser(response);
      setLoading(false);
    },
    onError: () => setToken(undefined),
  });

  useEffect(() => {
    if (!token) return setLoading(false); // No token ? close the splash and proceed to login page
    _axios._authorize(token); // 1- Authorize axios
    setLoading(true);
    VUM.mutate(token); // 2- Get user details
  }, [token]);

  return (
    <Provider value={{ auth: { isLogedIn: Boolean(token), token, login, isAccessible, user, logout } }}>
      {loading ? <AuthorizeLoading /> : children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };
