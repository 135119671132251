import { useNavigate } from "react-router-dom";
import DateFormat from "../../../DateFormat";
import Thumbnail from "../../../Thumbnail";
import NotificationBadge from "./NotificationBadge";
import { useContext } from "react";
import { AppLayoutContext } from "../../../../context/LayoutContext";
import { NotificationContext } from "../../../../context/NotificationContext";

export default function NotificationItem({ UUID, seen, code, display, payload, updatedAt, count }) {
  const navigate = useNavigate();
  const { notifications: ND } = useContext(AppLayoutContext);
  const { methods } = useContext(NotificationContext);
  const _handleClick = (code, payload) => {
    ND.close();
    methods.seen(UUID);
    setTimeout(() => {
      if (code === "NEW.MESSAGE") return navigate(`/messenger?conversation=${payload?.conversationID}&lead=${payload?.leadID}`);
      if (code === "NEW.LEAD") return navigate(`/leads/${payload?.leadID}/profile/overview`);
    }, 500);
  };
  return (
    <div onClick={() => _handleClick(code, payload)} className="d-flex align-items-center border-bottom p-3 bg-hover-light">
      <Thumbnail mediaID={display?.thumbnail} className="symbol symbol-30px symbol-circle" />
      <div className="d-flex flex-column ms-3">
        <span className="fw-bold">{display?.name}</span>
        <span className="fs-7 text-gray-600">{code}</span>
      </div>
      {!seen && <NotificationBadge value={count} />}
      <div className="d-flex flex-grow-1 align-items-center justify-content-end">
        {updatedAt && (
          <span className="text-muted">
            <DateFormat date={updatedAt} />
          </span>
        )}
      </div>
    </div>
  );
}
