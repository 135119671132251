import ReactDOM from "react-dom";
import facebookService from "./app/services/facebook.service";

import { Chart, registerables } from "chart.js";
import { QueryClient, QueryClientProvider } from "react-query";

// Apps
import { MetronicI18nProvider } from "./_metronic/i18n/Metronici18n";
import "./_metronic/assets/sass/style.scss";
import "./_metronic/assets/sass/style.react.scss";
import { AppRoutes } from "./app/routing/AppRoutes";

// Sentry
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { SENTRY_URL } from "./app/config";
import { AppConfigProvider } from "./app/context/AppContext";
import { AppLayoutProvider } from "./app/context/LayoutContext";
import { AuthProvider } from "./app/context/AuthContext";

import _axios from "./setup/_axios";

Chart.register(...registerables);

// React Query
const queryClient = new QueryClient();

// Sentry
Sentry.init({
  dsn: SENTRY_URL,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

// Setup Axios Interceptors
_axios._initiate();

// Facebook JS SDK
facebookService.init();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <MetronicI18nProvider>
      <AuthProvider>
        <AppConfigProvider>
          <AppLayoutProvider>
            <AppRoutes />
          </AppLayoutProvider>
        </AppConfigProvider>
      </AuthProvider>
    </MetronicI18nProvider>
  </QueryClientProvider>,
  document.getElementById("root")
);
