export default function AppSwitch({ field, form: { values, setFieldValue }, meta, ...props }) {
  return (
    <div className={`form-check form-switch d-flex align-items-center h-40px justify-content-start ${props.className}`}>
      <input
        {...field}
        {...props}
        className="form-check-input mb-0"
        type="checkbox"
        checked={field.value}
        onChange={(e) => setFieldValue(field.name, e.target.checked)}
      />
    </div>
  );
}
