import { lazy, Suspense, useContext, useMemo } from "react";
import { CallContext } from "../../context/CallContext";
import { ChatContext } from "../../context/ChatContext";
import NotificationsDrawer from "../../components/drawers/notifications/NotificationsDrawer";

const ChatDrawer = lazy(() => import("./messenger/ChatDrawer"));
const TwilioCallDrawer = lazy(() => import("./twilio-call/TwilioCallDrawer"));

export default function DrawersContainer() {
  const { _isEnabled: callIsEnabled } = useContext(CallContext);
  const { _isEnabled: chatIsEnabled } = useContext(ChatContext);

  const renderTwilioCallDrawer = useMemo(
    () => (
      <Suspense fallback={<></>}>
        <TwilioCallDrawer />
      </Suspense>
    ),
    [callIsEnabled]
  );
  const renderChatDrawer = useMemo(
    () => (
      <Suspense fallback={<></>}>
        <ChatDrawer />
      </Suspense>
    ),
    [chatIsEnabled]
  );

  return (
    <>
      {callIsEnabled && renderTwilioCallDrawer}
      {chatIsEnabled && renderChatDrawer}
      <NotificationsDrawer />
    </>
  );
}
