import toast from "../app/helpers/toast";
import { HIDDEN_TOAST_ERROR_CODES, I18N_CONFIG_KEY } from "../app/config";
import axios from "axios";

const _initiate = () => {
  const locale = localStorage.getItem(I18N_CONFIG_KEY) ? JSON.parse(localStorage.getItem(I18N_CONFIG_KEY))?.selectedLang : "en";
  axios.defaults.headers.Accept = "application/json";
  axios.defaults.headers["App-Locale"] = locale;
  axios.interceptors.response.use(
    (response) => {
      if (response.headers["content-type"] !== "application/json; charset=utf-8") return response;
      if (response.data.status !== 200) {
        if (!HIDDEN_TOAST_ERROR_CODES.includes(response.data.status)) {
          const message = response.data.error?.message;
          if (Array.isArray(message)) message?.map((msg) => toast.warning(msg));
          else toast.warning(message);
        }
        throw response.data.error;
      }
      return response.data.data;
    },
    (error) => {
      console.log(error);
      throw "API Communication Error";
    }
  );
};

const _authorize = (token) => {
  axios.interceptors.request.use(
    (config) => {
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (err) => Promise.reject(err)
  );
};

export default { _initiate, _authorize };
