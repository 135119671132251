const MENU = [
  {
    name: "MENU.BUSINESS",
    icon: "fa fa-briefcase",
    code: "company:update",
    paths: [
      {
        name: "MENU.BUSINESS.COMPANY",
        to: "/settings/general",
        icon: "fa fa-info-circle",
        code: "company:update",
      },
      {
        name: "MENU.BUSINESS.LOCATIONS",
        to: "/clinics",
        icon: "fa fa-map-marker-alt",
        code: "clinics:update",
      },
      {
        name: "MENU.BUSINESS.CATEGORIES",
        to: "/categories",
        icon: "fa fa-bars",
        code: "category:update",
      },
      {
        name: "MENU.BUSINESS.TREATMENTS",
        to: "/treatments",
        icon: "fa fa-heartbeat",
        code: "treatment:update",
      },
      {
        name: "MENU.BUSINESS.TEAM",
        to: "/staff",
        icon: "fa fa-users-cog",
        code: "staff:update",
      },
      {
        name: "MENU.BUSINESS.ACTIONS",
        to: "/actions",
        icon: "fa fa-exchange-alt",
        code: "action:update",
      },
      {
        name: "MENU.BUSINESS.MEDIA_TAGS",
        to: "/media-tags",
        icon: "fa fa-tag",
        code: "mediaTag:update",
      },
      {
        name: "MENU.BUSINESS.SOURCES",
        to: "/sources",
        icon: "fa fa-filter",
        code: "source:update",
      },
      {
        name: "MENU.BUSINESS.STATUSES",
        to: "/labels",
        icon: "fa fa-thermometer-half",
        code: "label:update",
      },
      {
        name: "MENU.BUSINESS.FIELDS",
        icon: "fa fa-align-left",
        code: "field:update",
        paths: [
          {
            name: "MENU.BUSINESS.FIELDS.CUSTOM_FIELDS",
            to: "/fields",
            icon: "fa fa-align-left",
            code: "field:update",
          },
          {
            name: "MENU.BUSINESS.FIELDS.MAPPING",
            to: "/fields-mapping",
            icon: "fa fa-map",
            code: "map:update",
          },
        ],
      },
      {
        name: "MENU.ACCOUNTING",
        icon: "fa fa-coins",
        paths: [
          { name: "MENU.ACCOUNTING.EXCHANGE_RATES", code: "company:update", to: "/rates", icon: "fa fa-euro-sign" },
          {
            name: "MENU.ACCOUNTING.TAX_GROUPS",
            to: "/taxs",
            icon: "fa fa-percentage",
            code: "tax:update",
          },
        ],
      },
      // {
      //   name: "MENU.BUSINESS.LOCALES",
      //   to: "/settings/locales",
      //   icon: "fa fa-globe-americas",
      //   code: "translation:update",
      // },
      {
        name: "MENU.BILLING",
        to: "/billing/dashboard",
        icon: "fa fa-server",
        code: "company:update:finance",
      },
    ],
  },
  {
    name: "MENU.INTEGRATIONS",
    icon: "fa fa-plug",
    paths: [
      // Website
      {
        name: "MENU.INTEGRATIONS.WEBSITE",
        icon: "fa fa-globe",
        code: "integration:manage:website",
        paths: [
          {
            name: "MENU.INTEGRATIONS.WEBSITE.INSTRUCTIONS",
            to: "/integrations/web/instructions",
            icon: "fa fa-info-circle",
          },
          {
            name: "MENU.INTEGRATIONS.WEBSITE.DOMAINS",
            to: "/integrations/web/domains",
            icon: "fa fa-link",
          },
          {
            name: "MENU.INTEGRATIONS.WEBSITE.FIELDS",
            to: "/integrations/web/analytics/fields",
            icon: "fa fa-align-left",
          },
          {
            name: "MENU.INTEGRATIONS.WEBSITE.ANALYTICS",
            to: "/integrations/web/analytics",
            icon: "fa fa-chart-bar",
          },
        ],
      },
      // Whatsapp
      {
        name: "MENU.INTEGRATIONS.WHATSAPP",
        icon: "fab fa-whatsapp",
        to: "/integrations/whatsapp/web",
        code: "integration:manage:whatsapp",
      },
      {
        name: "MENU.INTEGRATIONS.CHATBOT",
        icon: "fa fa-podcast",
        to: "/integrations/chatbot",
        code: "integration:manage:chatbot",
        paths: [
          {
            name: "MENU.INTEGRATIONS.CHATBOT.SETTINGS",
            to: "/integrations/chatbot/settings",
            icon: "fa fa-cog",
          },
          {
            name: "MENU.INTEGRATIONS.CHATBOT.MESSAGE_TEMPLATES",
            to: "/integrations/chatbot/message-templates",
            icon: "fa fa-sms",
          },
        ],
      },
      {
        name: "MENU.INTEGRATIONS.FACEBOOK",
        to: "",
        icon: "fab fa-facebook",
        to: "/integrations/facebook/pages",
        code: "integration:manage:facebook",
      },
      // Gateways
      {
        name: "MENU.INTEGRATIONS.GATEWAYS",
        icon: "fa fa-coins",
        code: "integration:manage:gateways",
        paths: [
          {
            name: "MENU.INTEGRATIONS.IYZICO",
            to: "/integrations/iyzico",
            icon: "fa fa-caret-right",
          },
          {
            name: "MENU.INTEGRATIONS.STRIPE",
            to: "/integrations/stripe",
            icon: "fa fa-caret-right",
          },
        ],
      },
      {
        name: "MENU.INTEGRATIONS.VOICE_CALL",
        icon: "fa fa-phone",
        code: "integration:manage:voice",
        paths: [
          {
            name: "MENU.INTEGRATIONS.VOICE_CALL.CALLERIDS",
            to: "/integrations/voice-call/caller-ids",
            icon: "fa fa-headset",
          },
          {
            name: "MENU.INTEGRATIONS.VOICE_CALL.PHONE_NUMBERS",
            to: "/integrations/voice-call/numbers",
            icon: "fa fa-bars",
          },
        ],
      },
    ],
  },
  {
    name: "MENU.LEADS_MANAGEMENT",
    icon: "fa fa-users",
    paths: [
      {
        name: "MENU.LEADS_MANAGEMENT.HOOKS",
        to: "/hooks",
        icon: "fa fa-magic",
        code: "hook:update",
      },
      {
        name: "MENU.LEADS_MANAGEMENT.PROFILERS",
        to: "/profilers",
        icon: "fa fa-bullseye",
        code: "profiler:update",
      },
      {
        name: "MENU.LEADS_MANAGEMENT.LEVELS",
        to: "/levels",
        icon: "fa fa-stream",
        code: "level:update",
      },
      {
        name: "MENU.LEADS_MANAGEMENT.TIMELINES",
        icon: "fa fa-tasks",
        code: "timelineTemplate:update",
        to: "/timelines/templates",
      },
      {
        name: "MENU.LEADS_MANAGEMENT.FORMS",
        icon: "fa fa-pen-square",
        code: "form:update",
        to: "/forms",
      },
      {
        name: "MENU.LEADS_MANAGEMENT.IMPORT",
        to: "/import",
        icon: "fa fa-download",
        code: "lead:import",
      },
      {
        name: "MENU.REPORTS.METRICS",
        code: "lead:metric",
        icon: "fa fa-cog",
        to: "/metrics",
      },
      {
        name: "MENU.REPORTS",
        icon: "fa fa-copy",
        to: "/reports/leads",
        code: "lead:report",
      },
      {
        name: "MENU.PUBLICATIONS",
        icon: "fa fa-print",
        paths: [
          {
            name: "MENU.PUBLICATIONS.DOCUMENTS",
            icon: "fa fa-book",
            to: "/publications/documents",
            code: "document:update",
          },
          {
            name: "MENU.PUBLICATIONS.TEMPLATES",
            icon: "fa fa-file-word",
            code: "template:update",
            to: "/publications/templates",
          },
        ],
      },
    ],
  },
  {
    name: "MENU.ACCESS_CONTROL",
    icon: "fa fa-shield-alt",
    paths: [
      { name: "MENU.ACCESS_CONTROL.AGENTS", to: "/agents", icon: "fa fa-user", code: "user:update" },
      {
        name: "MENU.ACCESS_CONTROL.GROUPS",
        to: "/agent-groups",
        icon: "fa fa-users-cog",
        code: "group:update",
      },
      { name: "MENU.ACCESS_CONTROL.ROLES", to: "/roles", icon: "fa fa-key", code: "role:update" },

      {
        name: "MENU.LOGS",
        icon: "fa fa-copy",
        code: "report:read",
        paths: [
          {
            name: "MENU.LOGS.LEAD_ACCESS",
            to: "/logs/access",
            icon: "fa fa-users",
            code: "report:read:access",
          },
          {
            name: "MENU.LOGS.VOICE_CALLS",
            to: "/logs/call",
            icon: "fa fa-phone",
            code: "report:read:call",
          },
          {
            name: "MENU.LOGS.WHATSAPP",
            to: "/logs/whatsapp",
            icon: "fab fa-whatsapp",
            code: "report:read:whatsapp",
          },
          // {
          //   name: "MENU.LOGS.SMS",
          //   to: "/logs/sms",
          //   icon: "fa fa-bars",
          //   code: "report:read:sms",
          // },
          {
            name: "MENU.LOGS.FACEBOOK_MESSAGES",
            to: "/logs/facebook",
            icon: "fab fa-facebook",
            code: "report:read:facebook",
          },
        ],
      },
    ],
  },
  {
    name: "MENU.INTERNAL",
    service: "scheduler",
    icon: "fa fa-bars",
    code: "internal:manage",
    paths: [
      {
        name: "MENU.INTERNAL.ROOMS",
        service: "scheduler",
        icon: "fa fa-vector-square",
        to: "/internal/rooms",
      },
      {
        name: "MENU.INTERNAL.EQUIPMENT",
        service: "scheduler",
        icon: "fa fa-shapes",
        to: "/internal/equipment",
      },
      {
        name: "MENU.INTERNAL.INVENTORY",
        service: "scheduler",
        icon: "fa fa-bars",
        paths: [
          {
            name: "MENU.INTERNAL.MATERIALS",
            service: "scheduler",
            icon: "fa fa-bars",
            to: "/internal/inventory/materials",
          },
          {
            name: "MENU.INTERNAL.BRANDS",
            service: "scheduler",
            icon: "fa fa-bars",
            to: "/internal/inventory/brands",
          },
          {
            name: "MENU.INTERNAL.IMEX",
            service: "scheduler",
            icon: "fa fa-bars",
            to: "/internal/inventory/imex",
          },
        ],
      },
      {
        name: "MENU.INTERNAL.CALENDAR",
        service: "scheduler",
        icon: "fa fa-calendar",
        to: "/internal/calendar",
      },
    ],
  },
  {
    name: "MENU.BOOKINGS",
    service: "booking",
    icon: "fa fa-hotel",
    code: "booking:manage",
    paths: [
      {
        name: "MENU.BOOKINGS.HOTELS",
        service: "booking",
        icon: "fa fa-hotel",
        to: "/bookings/hotels",
      },
      {
        name: "MENU.BOOKINGS.DRIVERS",
        service: "booking",
        icon: "fa fa-user",
        to: "/bookings/drivers",
      },
      {
        name: "MENU.BOOKINGS.PLACES",
        service: "booking",
        icon: "fa fa-map-marker-alt",
        to: "/bookings/places",
      },
      {
        name: "MENU.BOOKINGS.RESERVATIONS",
        service: "booking",
        icon: "fa fa-calendar",
        to: "/bookings/reservations",
      },
      {
        name: "MENU.BOOKINGS.TRANSFERS",
        service: "booking",
        icon: "fa fa-shuttle-van",
        to: "/bookings/transfers",
      },
    ],
  },
  {
    name: "MENU.MARKETING",
    icon: "fa fa-bars",
    code: "marketing:manage",
    service: "marketing",
    paths: [
      {
        name: "MENU.MARKETING.SMS",
        icon: "fa fa-bars",
        to: "/marketing/sms",
        service: "marketing",
      },
    ],
  },
];

const limit = (branch, isAccessible, isEnabled) => {
  const result = [];
  branch.map((b) => {
    if (!isAccessible(b.code) || !isEnabled(b.service)) return;
    if (b.paths) {
      const children = limit(b.paths, isAccessible, isEnabled);
      if (children.length > 0) result.push({ name: b.name, icon: b.icon, code: b.code, paths: children });
    } else result.push(b);
  });
  return result;
};

const bootstrap = (isAccessible, isEnabled) => limit(MENU, isAccessible, isEnabled);
export default { bootstrap };
