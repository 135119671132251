const getIcon = (code) => {
  const resources = {
    whatsapp: "whatsapp.png", // source, channel
    voiceCall: "telephone.png", // source
    FBMessenger: "facebook.png", // source
    FBMessaging: "facebook.png", // channel
    facebook: "facebook.png", // channel
    FBLead: "facebook.png", // source
    instagram: "instagram.png", // channel
    form: "website.png",
    sms: "sms.png",
    manual: "manual.png",
    messenger: "messenger.png",
    "video/mp4": "mp4.png",
    "video/mpeg": "mpeg.png",
    "audio/mpeg": "mp3.png",
    "image/jpeg": "jpeg.png",
    "image/png": "png.png",
    "application/zip": "zip.png",
    "application/vnd.rar": "rar.png",
    "application/pdf": "pdf.png",
    "audio/ogg": "ogg.png",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "sheets.png",
    invoice: "invoice.png",
    verified: "verified.png",
  };
  return resources[code] ?? "";
};
export default function AppIcon({ code, icon, onClick = () => {}, ...props }) {
  {
    icon && (
      <div className="bg-light h-40px w-40px d-flex align-items-center justify-content-center">
        <span className={`${icon} text-gray-500`} />
      </div>
    );
  }
  return <img src={`/media/icons/${getIcon(code)}`} onClick={onClick} {...props} />;
}
