import { useEffect } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { Login } from "./components/Login";
import { useMutation } from "react-query";
import axios from "axios";
import Endpoints from "../../config/endpoints";

const AuthLayout = () => {
  // Get Public Assets
  const GPM = useMutation(() =>
    axios.get(`${Endpoints.public}/${window.location.href.split("/")[2]}`)
  );

  useEffect(() => {
    GPM.mutate();
  }, []);

  return (
    <div
      className="d-flex align-items-center justify-content-center position-relative"
      style={{ height: "100vh" }}
    >
      <div className="d-flex flex-column align-items-center justify-content-center h-100 w-sm-100 w-xl-25">
        <img
          alt="Logo"
          src={`${Endpoints.cdn}/public/medias/${GPM.data?.logo}`}
          className="mb-10 h-45px"
        />
        <Outlet />
        <span className="position-absolute bottom-0 my-10 text-gray-600 fs-4 fw-semibold ls-2">
          PATIENTSONBOARD
        </span>
      </div>
    </div>
  );
};

export default function AuthPage() {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path="login" element={<Login />} />
        <Route index element={<Login />} />
      </Route>
    </Routes>
  );
}
