export default function AppDrawer({ children, show, onClose }) {
  return (
    <>
      <div className={`bg-white drawer drawer-end w-100 w-lg-25 drawer-${show ? "on" : "off"}`} data-kt-drawer="off" style={{ overflow: "hidden" }}>
        {children}
      </div>
      {show && <div className="drawer-overlay" onClick={onClose}></div>}
    </>
  );
}
