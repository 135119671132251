import { FC } from "react";
import { useLang } from "./Metronici18n";
import { IntlProvider } from "react-intl";

import enMessages from "./messages/en.json";
import trMessages from "./messages/tr.json";

const allMessages = {
  en: enMessages,
  tr: trMessages,
};

const I18nProvider: FC = ({ children }) => {
  const locale = useLang();
  const messages = allMessages[locale];

  return (
    <IntlProvider locale={locale} messages={messages} onError={() => {}}>
      {children}
    </IntlProvider>
  );
};

export { I18nProvider };
