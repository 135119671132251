import AppToolbar from "./AppToolbar";

export default function ServiceDenied({ service }) {
  return (
    <>
      <AppToolbar />
      <div className="d-flex flex-column flex-center p-10 bg-white">
        <span className="fs-4 text-uppercase ls-1 text-danger">Service Disabled</span>
        <span className="text-gray-700 mt-3 fs-6">
          This requested service <code className="text-gray-600">{service}</code> is not active.
        </span>
        <span className="mt-2">[Please call the the system adminstrator for further information.]</span>
      </div>
    </>
  );
}
