import { Route, Routes, Navigate, Outlet } from "react-router-dom";
import AccessDenied from "./AccessDenied";
import ServiceDenied from "./ServiceDenied";
import { PageTitle } from "../../_metronic/layout/core";
import { Suspense, useContext } from "react";
import TopBarProgress from "react-topbar-progress-indicator";
import { useIntl } from "react-intl";
import { AppContext } from "../context/AppContext";
import { AuthContext } from "../context/AuthContext";

const DefaultMaster = () => (
  <>
    <Outlet />
  </>
);

const SuspensedView = ({ children }) => {
  TopBarProgress.config({
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export default function RouteContainer({ paths, layout = <DefaultMaster /> }) {
  const intl = useIntl();
  const {
    CM: { isEnabled },
  } = useContext(AppContext);
  const {
    auth: { isAccessible },
  } = useContext(AuthContext);
  return (
    <Routes>
      <Route element={layout}>
        {paths.map(({ code, service, path, title, nugget, element, description }, _index) => (
          <Route
            key={_index}
            path={path}
            element={
              <SuspensedView>
                <PageTitle description={description} nugget={nugget}>
                  {intl.formatMessage({ id: title ?? "-" })}
                </PageTitle>
                {!isAccessible(code) ? <AccessDenied code={code} /> : !isEnabled(service) ? <ServiceDenied service={service} /> : element}
              </SuspensedView>
            }
          />
        ))}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
}
