import moment from "moment-timezone";
import { useContext } from "react";
import { AppContext } from "../context/AppContext";
export default function DateFormat({ date, className, format = undefined }) {
  const { PM, GM } = useContext(AppContext);
  if (!date) return <></>;
  return (
    <span className={className}>
      {moment(date)
        .tz(PM.profile.default?.timezone ?? GM.profile?.default?.timezone ?? "")
        ?.format(format ?? PM.profile.default?.dateFormat)}
    </span>
  );
}
