import React, { FC } from "react";
import { MenuInner } from "./MenuInner";

const Header: FC = () => {
  return (
    <div className="header-menu align-items-stretch">
      <div
        data-kt-menu="true"
        data-kt-menu-trigger="click"
        className="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch"
      >
        <MenuInner />
      </div>
    </div>
  );
};

export { Header };
