import { createEmptyValue } from "react-rte";

// API Base URL
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// SOCKET API URL
export const SOCKET_API_URL = process.env.REACT_APP_SOCKET_API_URL;

// Main App
export const APP_BASE_URL = `${API_BASE_URL}/app`;

// CDN Base URL
export const CDN_BASE_URL = process.env.REACT_APP_CDN_BASE_URL;

// CMS Module
export const CMS_BASE_URL = `${API_BASE_URL}/cms`;

// Analytics Module
export const ANALYTICS_BASE_URL = `${API_BASE_URL}/analytics`;

// Academy Module
export const ACADEMY_BASE_URL = `${API_BASE_URL}/academy`;

// SMS Marketing Module
export const SMS_BASE_URL = process.env.REACT_APP_SMS_BASE_URL;

// App Name
export const APP_NAME = process.env.REACT_APP_APP_NAME;

// Facebook APP ID
export const FB_APP_ID = process.env.REACT_APP_FB_APP_ID;

// Google Maps API Key
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

// Image Placeholders
export const PLACEHOLDERS = {
  thumbnail: "/media/avatars/blank.png",
};

// SENTRY_URL
export const SENTRY_URL = "https://dbffc01af27b488ba8e86a663592f177@o1316113.ingest.sentry.io/4503948504793088";

export const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || "i18nConfig";

export const MLInitialValues = { en: "", fr: "", ru: "" };

export const FB_PERMISSIONS = {
  PAGE: [
    "pages_show_list",
    "pages_manage_metadata",
    "pages_read_engagement",
    "pages_messaging",
    "pages_manage_ads",
    "leads_retrieval",
    "instagram_basic",
    "instagram_manage_messages",
  ],
  BUSINESS: ["business_management"],
};

export const RTEMLInitialValues = {
  en: createEmptyValue(),
  fr: createEmptyValue(),
  ru: createEmptyValue(),
};

export const WORKING_HOURS = {
  sat: { enabled: false, start: "09:00", end: "17:00" },
  sun: { enabled: false, start: "09:00", end: "17:00" },
  mon: { enabled: true, start: "09:00", end: "17:00" },
  tue: { enabled: true, start: "09:00", end: "17:00" },
  wed: { enabled: true, start: "09:00", end: "17:00" },
  thu: { enabled: true, start: "09:00", end: "17:00" },
  fri: { enabled: true, start: "09:00", end: "17:00" },
};

export const HIDDEN_TOAST_ERROR_CODES = [401];
