import { useMemo, useState } from "react";
import { useIntl } from "react-intl";
import MediaPickerModal from "./modals/MediaPickerModal";

export default function AppMediaPicker({ field, form, multiple = false, leadID, ...props }) {
  const [modal, setModal] = useState({ enabled: false });
  const intl = useIntl();
  const renderModal = useMemo(
    () => (
      <MediaPickerModal
        show={modal.enabled}
        onClose={() => setModal({ enabled: false })}
        defaultSelected={multiple ? field.value ?? [] : [field.value]}
        onSelect={(mediaIDs) => {
          form.setFieldValue(field.name, multiple ? mediaIDs : mediaIDs?.[0]);
          setModal({ enabled: false });
        }}
        leadID={leadID}
        multiple={multiple}
      />
    ),
    [modal.enabled]
  );
  return (
    <>
      <div
        className={`cursor-pointer d-flex align-items-center justify-content-start border p-3 border-gray-300 ${
          field.value ? `text-gray-700` : `text-muted`
        } bg-hover-light fs-7 ${props.className}`}
        onClick={() => setModal({ enabled: true })}
      >
        {field.value ? <span>{multiple ? field.value?.length : 1} Media Selected</span> : <span>{intl.formatMessage({ id: props.placeholder ?? "-" })}</span>}
      </div>
      {renderModal}
    </>
  );
}
