import { Link } from "react-router-dom";
import { menuItems } from "../aside/AsideMenuMain";
import { useIntl } from "react-intl";
import { useState } from "react";

const MobileMenu = () => {
  const [active, setActive] = useState(false);
  const intl = useIntl();
  return (
    <div className="app-navbar-item ms-1 ms-lg-3 d-lg-none">
      <div className="btn btn-icon active btn-active-color-info w-35px h-35px w-md-40px h-md-40px menu-dropdown" onClick={() => setActive(!active)}>
        <span className="svg-icon theme-light-show svg-icon-2">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="currentColor"></path>
            <path
              opacity="0.3"
              d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
              fill="currentColor"
            ></path>
          </svg>
        </span>
      </div>
      <div
        className="position-fixed border border-gray-300 shadow-sm mt-60px bg-white fw-semibold text-gray-700 text-hover-primary py-4 fs-base w-175px"
        style={{
          zIndex: 105,
          display: active ? "block" : "none",
        }}
      >
        {menuItems?.map((item, _index) => {
          return (
            <Link onClick={() => setActive(false)} className="text-gray-700 text-hover-primary" to={item.to}>
              <div className="px-5 py-3 bg-hover-light my-0" key={_index}>
                <span className={`fa ${item.fontIcon} me-3`} />
                <span className="">{intl.formatMessage({ id: item.title })}</span>
              </div>
            </Link>
          );
        })}
      </div>
      {active && <div style={{ position: "fixed", top: 0, bottom: 0, left: 0, right: 0 }} onClick={() => setActive(false)}></div>}
    </div>
  );
};
export default MobileMenu;
