import React, { createContext, useContext } from "react";
import { DefaultLayoutConfig } from "./DefaultLayoutConfig";
import { getEmptyCssClasses, getEmptyCSSVariables, getEmptyHTMLAttributes, LayoutSetup } from "./LayoutSetup";
import { ILayout, ILayoutCSSVariables, ILayoutCSSClasses, ILayoutHTMLAttributes } from "./LayoutModels";

export interface LayoutContextModel {
  config: ILayout;
  classes: ILayoutCSSClasses;
  attributes: ILayoutHTMLAttributes;
  cssVariables: ILayoutCSSVariables;
}

const LayoutContext = createContext<LayoutContextModel>({
  config: DefaultLayoutConfig,
  classes: getEmptyCssClasses(),
  attributes: getEmptyHTMLAttributes(),
  cssVariables: getEmptyCSSVariables(),
});

const LayoutProvider: React.FC = ({ children }) => {
  const { config, classes, attributes, cssVariables } = LayoutSetup;
  const value: LayoutContextModel = {
    config,
    classes,
    attributes,
    cssVariables,
  };
  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>;
};

export { LayoutContext, LayoutProvider };

export function useLayout() {
  return useContext(LayoutContext);
}
