import axios from "axios";
import { useContext, useEffect } from "react";
import { useMutation } from "react-query";
import Endpoints from "../app/config/endpoints";
import { AuthContext } from "../app/context/AuthContext";

export default function useGlobal() {
  const countries = useMutation(() => axios.get(`${Endpoints.misc}/countries`));
  const languages = useMutation(() => axios.get(`${Endpoints.misc}/languages`));
  const timezones = useMutation(() => axios.get(`${Endpoints.misc}/timezones`));
  const sources = useMutation(() => axios.get(Endpoints.source));
  const labels = useMutation(() => axios.get(Endpoints.label));
  const actions = useMutation(() => axios.get(Endpoints.action));
  const levels = useMutation(() => axios.get(Endpoints.level));
  const notificationCodes = useMutation(() => axios.get(`${Endpoints.notification}/codes`));
  const rates = useMutation(() => axios.get(Endpoints.rate));
  const clinics = useMutation(() => axios.get(Endpoints.clinic));
  const treatments = useMutation(() => axios.get(Endpoints.treatment));
  const personnel = useMutation(() => axios.get(Endpoints.staff));
  const users = useMutation(() => axios.get(Endpoints.user));
  const groups = useMutation(() => axios.get(Endpoints.group));
  const categories = useMutation(() => axios.get(Endpoints.category));
  const fields = useMutation(() => axios.get(Endpoints.field));
  const mediaTags = useMutation(() => axios.get(Endpoints.mediaTag));
  const taxGroups = useMutation(() => axios.get(Endpoints.tax));

  const allMutations = [
    countries,
    languages,
    timezones,
    sources,
    labels,
    actions,
    levels,
    notificationCodes,
    rates,
    clinics,
    treatments,
    personnel,
    users,
    groups,
    categories,
    fields,
    mediaTags,
    taxGroups,
  ];

  const {
    auth: { token },
  } = useContext(AuthContext);

  useEffect(() => {
    if (token) for (let m of allMutations) m.mutate();
  }, [token]);

  return {
    countries: countries.data ?? [],
    languages: languages.data ?? [],
    timezones: timezones.data ?? [],
    sources: sources.data ?? [],
    labels: labels.data ?? [],
    actions: actions.data ?? [],
    levels: levels.data ?? [],
    notificationCodes: notificationCodes.data ?? [],
    rates: rates.data ?? [],
    clinics: clinics.data ?? [],
    treatments: treatments.data ?? [],
    personnel: personnel.data ?? [],
    users: users.data ?? [],
    groups: groups.data ?? [],
    categories: categories.data ?? [],
    fields: fields.data ?? [],
    mediaTags: mediaTags.data ?? [],
    taxGroups: taxGroups.data ?? [],
    isLoading: allMutations.some((x) => x.isLoading),
  };
}
