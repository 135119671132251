import { useContext } from "react";
import { PLACEHOLDERS } from "../config";
import Endpoints from "../config/endpoints";
import { AuthContext } from "../context/AuthContext";

/**
 * Media that's stored by us
 */

export default function LocalMedia({ mediaID, mime = "image/", ...props }) {
  const {
    auth: { token },
  } = useContext(AuthContext);
  const type = mime?.split("/")[0];
  const url = mediaID ? `${Endpoints.cdn}/app/medias/${mediaID}/download?token=${token}` : `${PLACEHOLDERS.thumbnail}`;
  return (
    <>
      {type === "image" && <img src={url} {...props} loading="lazy" style={{ objectFit: "cover" }} />}
      {type === "video" && <video src={url} muted controls {...props} />}
      {type === "audio" && <audio src={url} controls />}
    </>
  );
}
