import Select from "react-select";
export default function AppSelect({ field, form: { setFieldValue } = {}, options = [], loading = false, required = false, ...props }) {
  const customStyles = {
    control: (props) => ({
      ...props,
      border: "1px solid #e4e6ef",
      borderRadius: 1,
      minHeight: "40px",
    }),
    menu: (props) => ({
      ...props,
      borderRadius: 1,
    }),
  };
  return (
    <Select
      isDisabled={props.disabled}
      styles={customStyles}
      instanceId={field?.name}
      options={[props.placeholder && { label: props.placeholder, value: "" }, ...options]}
      isOptionDisabled={required ? ({ value }) => !Boolean(value) : undefined}
      {...(field && { onChange: ({ value }) => setFieldValue(field?.name, value) })}
      {...(field && { value: options ? options.find((option) => option.value === field?.value) : "" })}
      {...props}
      isLoading={loading}
    />
  );
}
