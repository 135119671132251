import { MenuItem } from "./MenuItem";
import { MenuInnerWithSub } from "./MenuInnerWithSub";
import { useIntl } from "react-intl";
import MENU from "../../../../app/config/MENU";
import { useContext } from "react";
import { AppContext } from "../../../../app/context/AppContext";
import { AuthContext } from "../../../../app/context/AuthContext";
const bootstrap = (branch, intl, isSub = false) => {
  return branch.map((b, _index) => {
    if (b.paths)
      return (
        <MenuInnerWithSub
          key={b.name}
          title={intl.formatMessage({ id: b.name })}
          icon={b.icon}
          hasArrow
          to="/"
          menuPlacement={isSub ? "right-start" : "bottom-start"}
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          {bootstrap(b.paths, intl, true)}
        </MenuInnerWithSub>
      );
    else return <MenuItem title={intl.formatMessage({ id: b.name })} to={b.to} icon={b.icon} key={_index} />;
  });
};

export function MenuInner() {
  const {
    auth: { isAccessible },
  } = useContext(AuthContext);

  const {
    CM: { isEnabled },
  } = useContext(AppContext);
  const intl = useIntl();
  return (
    <>
      <MenuItem title={intl.formatMessage({ id: "MENU.DASHBOARD" })} to="/dashboard" />
      {bootstrap(MENU.bootstrap(isAccessible, isEnabled), intl)}
    </>
  );
}
