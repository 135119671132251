import { createContext, useContext } from "react";
import useTwilioCall from "../../hooks/useTwilioCall";
import { AppContext } from "./AppContext";

const CallContext = createContext();
const { Provider } = CallContext;

const CallProvider = ({ children }) => {
  const { CM } = useContext(AppContext);

  const _isEnabled = CM.services?.call;
  const callManager = useTwilioCall(_isEnabled);

  return (
    <Provider
      value={{
        callManager,
        _isEnabled,
      }}
    >
      {children}
    </Provider>
  );
};
export { CallContext, CallProvider };
