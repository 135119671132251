/* eslint-disable jsx-a11y/anchor-is-valid */
import { Header } from "./Header";
import MobileMenu from "./MobileMenu";
import Topbar from "./Topbar";

export function HeaderWrapper() {
  return (
    <div className="header d-flex px-5 align-items-center border-bottom border-gray-300">
      <MobileMenu />
      <Header />
      <Topbar />
    </div>
  );
}
