import { createContext, useState } from "react";
import useGoogleMap from "../../hooks/useGoogleMap";

const AppLayoutContext = createContext();
const { Provider } = AppLayoutContext;

const AppLayoutProvider = ({ children }) => {
  const [sidebar, setSidebar] = useState("open");
  const [nugget, setNugget] = useState({ enabled: false, code: undefined });
  const [call, setCall] = useState({ enabled: false, number: undefined });
  const [messenger, setMessenger] = useState({ enabled: false });
  const [notification, setNotification] = useState({ enabled: false });
  const [dialog, setDialog] = useState({
    enabled: false,
    mode: "info",
    title: undefined,
    body: undefined,
    confirmButtonTitle: "BTN.OK",
    cancelButtonTitle: "BTN.CANCEL",
    onConfirm: () => {},
  });

  const components = {
    notifications: {
      enabled: notification.enabled,
      show: () => setNotification({ enabled: true }),
      close: () => setNotification({ enabled: false }),
    },
    nugget: {
      enabled: nugget.enabled,
      code: nugget.code,
      show: (code) => setNugget({ enabled: true, code }),
      close: () => nugget.enabled && setNugget({ enabled: false, code: undefined }),
    },
    call: {
      enabled: call.enabled,
      number: call.number,
      show: (number) => setCall({ enabled: true, number }),
      close: () => setCall({ enabled: false, number: undefined }),
    },
    messenger: {
      enabled: messenger.enabled,
      conversationID: messenger.conversationID,
      leadID: messenger.leadID,
      show: (leadID, conversationID) => setMessenger({ enabled: true, leadID, conversationID }),
      close: () => setMessenger({ enabled: false }),
    },
    dialog: {
      ...dialog,
      enabled: dialog.enabled,
      mode: dialog.mode,
      title: dialog.title,
      body: dialog.body,
      confirmButtonTitle: dialog.confirmButtonTitle,
      cancelButtonTitle: dialog.cancelButtonTitle,
      onConfirm: dialog.onConfirm,
      show: ({ mode, title, body, onConfirm, confirmButtonTitle, cancelButtonTitle }) =>
        setDialog({ enabled: true, mode, title, body, confirmButtonTitle, cancelButtonTitle, onConfirm }),
      close: () => dialog.enabled && setDialog({ enabled: false }),
    },
    sidebar: {
      state: sidebar,
      open: () => setSidebar("open"),
      close: () => setSidebar("close"),
    },
  };

  return (
    <Provider
      value={{
        ...components,
        GoogleMap: useGoogleMap(),
      }}
    >
      {children}
    </Provider>
  );
};
export { AppLayoutContext, AppLayoutProvider };
